import { Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from "react-query/devtools";
import { ConfigProvider } from "antd";
import zhCN from 'antd/lib/locale/zh_CN';
import TickDetail from "./pages/TicketDetail";
import EnterGroup from './pages/EnterGroup';
import History from './pages/History';
import './App.less';
import OpenTicket from "./pages/OpenTicket";

export const HostMap = {
  'prod': {
    client: "client.gllue.com",
    statuspage: "statuspage.internal.gllue.com",
    baseHost: "gllue.com",
  },
  'test': {
    client: "client.gllue.net",
    statuspage: "statuspage.internal.gllue.net",
    baseHost: "gllue.net",
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false
    },
    mutations: {
      retry: false
    }
  }
});

function App() {
  return (
    <div className="App">
      <ConfigProvider locale={zhCN}>
        <QueryClientProvider client={queryClient}>
          <Routes>
            <Route path="/" element={<History />}></Route>
            <Route path="/ticket_detail/:ticketId" element={<TickDetail />}></Route>
            <Route path="/ticket_detail/group/:ticketId" element={<EnterGroup />}></Route>
            <Route path="/open_ticket" element={<OpenTicket hasBack={false}/>}></Route>
            <Route path="/history" element={<History />}></Route>
          </Routes>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </ConfigProvider>
    </div>
  );
}

export default App;
