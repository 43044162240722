import axios from 'axios'
import { message } from 'antd'

const instance = axios.create({
  // baseURL: 'http://abcdefgh.vaiwan.cn'
  baseURL: process.env.NODE_ENV === 'development' ? '/api/ticketSystem/v1' : '/ticketSystem/v1'
  // baseURL: '/api'
})

instance.interceptors.response.use((response) => {
  if (response.status === 200) {
    const { code, data, msg } = response.data
    if (code === 200) {
      return data
    } else {
      message.error(msg)
      return Promise.reject(data)
    }
  } else {
    return Promise.reject({})
  }
}, (err) => {
  if (err.response.status === 401) {
    let data = err?.response.data
    try {
      window.location.href = data.redirectUrl
      return Promise.reject()
    } catch(err) {
      console.log(err)
    }
  } else {
    let errmsg = err?.response?.data?.msg
    message.error(errmsg)
    return Promise.reject(errmsg)
  }
})

export default instance
