import ajax from './ajax'

export const queryCategory = async() => {
  const res = await ajax.get('/category')
  
  return res
}

export const queryDealer = async() => {
  const res = await ajax.get('/dealer')

  return res
}

export const querySender = async () => { 
  const res = await ajax.get('/sender')

  return res
}

export const queryGllueClient = async (params) => {
  const res = await ajax.get('/gllueclient', {
    params: params
  })

  return res
}
