import { AutoComplete, Input, Spin} from 'dingtalk-design-desktop';
import {PageContainer, Form, Toast} from "dingtalk-design-mobile";
import {useState} from "react";
import {createTicket, queryGllueClient} from "../api";

const OpenTicket = () => {
  const [spinning, setSpinning] = useState(false);
  const [form] = Form.useForm();
  const actions = [
    {
      text: '取消',
      type: 'cancel',
    },
    {
      text: '确定',
      type: 'ok',
      onClick: () => form.submit()
    }
  ];
  const [options, setOptions] = useState([]);

  const handleSearch = (query) => {
    const result = queryGllueClient({search: query})
    result.then((data) => {
      const opt = []
      if (data) {
        data.gllueClients.map((client) => {
          return opt.push({
            value: client.code,
            label: (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <span>
                  {client.code}
                </span>
                <span style={{color: "#007fff"}}>
                  {client.name}
                </span>
              </div>
            ),
          })
        })
      }
      setOptions(opt)
    })
  }

  return (
    <Spin visible={spinning}>
      <PageContainer
        title="创建工单"
        full={true}
        strongMainAction={true}
        actions={actions}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={async (v) => {
            setSpinning(true)
            await createTicket({
              code: v.client_code,
              issueDesc: v.ticket_summary,
            }).then((res) => {
              if (res) {
                const msg = "创建工单成功: " + res.ticketId
                Toast.success({
                  content: msg
                });
                window.open(res.redirectUrl)
              }
            })
            setSpinning(false)
          }}
          onFinishFailed={(err) => {
            Toast.fail({
              content: err.errorFields[0].errors[0],
            });
            setSpinning(false)
          }}
        >
          <Form.Item name="client_code" label="ClientCode 或客户简称" rules={[{required: true, message: "请填写客户 Code"}]}>
            <AutoComplete
              dropdownMatchSelectWidth={252}
              style={{ width: "100%" }}
              options={options}
              onSearch={handleSearch}
              placeholder="请输入"
            />
          </Form.Item>
          <Form.Item name="ticket_summary" label="问题描述" rules={[{required: true, message: "请填写问题描述"}]}>
            <Input.TextArea placeholder="请输入" />
          </Form.Item>
        </Form>
      </PageContainer>
    </Spin>
  )
}

export default OpenTicket
