import { useQuery } from "react-query"
import { queryCategory } from "../api"

export const useCategory = () => {
  const { data } = useQuery('query-category', queryCategory, {
    placeholderData: {
      categoryInfo: []
    }
  })
  return {
    data: data?.categoryInfo || []
  }
}
