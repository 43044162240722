import { useState, useEffect } from "react"
import { Modal, Form, Input, message, Select } from 'antd'
import ReactDOM from 'react-dom'
import { useTicket } from '../logic/useTicket'
import { useCategory } from "../logic/useCategory"
import * as dd from 'dingtalk-jsapi'

const UpdateTicket = (props) => {
  const { close, visible, id } = props
  const { data: ticketInfo, update: updateTicket } = useTicket(id)
  const [form] = Form.useForm()
  useEffect(() => {
    form.setFieldsValue(ticketInfo)
  }, [ticketInfo, form])
  const submit = async () => {
    const values = form.getFieldsValue()
    try {
      await updateTicket(values)
      message.success('成功')
      form.resetFields()
      close()
    } catch(err) {
    }
  }

  return (
    <>
      <Modal
        title="更改工单"
        width={540}
        visible={visible}
        onCancel={close}
        onOk={submit}
      >
        <Form
          form={form}
          labelCol={{ span: 6 }}
          initialValues={ticketInfo}
        >
          <Form.Item label="Code" name="code">
            <Input placeholder="输入code" disabled/>
          </Form.Item>
          <Form.Item label="描述" name="issueDesc">
            <Input.TextArea autoSize={{minRows: 3}} placeholder="输入描述"/>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export const useUpdateTicketModal = (ticketId) => {
  const [visible, setVisible] = useState(false)
  const component = ReactDOM.createPortal(<UpdateTicket visible={visible} close={() => setVisible(false)} id={ticketId}></UpdateTicket>, document.querySelector('body'))

  return {
    open: () => setVisible(true),
    component: component
  }
}

const ConcludeTicket = (props) => {
  const { close, visible, id, concluded } = props
  const { conclude: concludeTicket, data, updateConclude } = useTicket(id)

  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue({
      concludeText: data.concludeDesc
    })
  }, [form, data])
  const submit = async () => {
    form.validateFields().then(async (values) => {
      try {
        if (concluded) {
          await updateConclude(values)
        } else {
          await concludeTicket(values)
        }
        message.success(concluded ? '更新总结成功' : '总结成功')
        form.resetFields()
        close()
      } catch(err) {}
    })
  }

  return (
    <>
      <Modal
        title={concluded ? '修改总结' : '总结'}
        width={540}
        visible={visible}
        onCancel={close}
        onOk={submit}
      >
        <Form
          form={form}
          labelCol={{ span: 6 }}
        >
          <Form.Item label="总结文案" name="concludeText" rules={[
            {
              required: true,
            }
          ]}>
            <Input.TextArea autoSize={{minRows: 3}} placeholder="输入总结文案"/>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

const UpdateTicketTag = (props) => {
  const { close, visible, id } = props
  const { updateTag: updateTicketTag } = useTicket(id)
  const [form] = Form.useForm()
  const { data: categories } = useCategory()
  const submit = async () => {
    form.validateFields().then(async (values) => {
      try {
        await updateTicketTag(values)
        message.success('更新标签成功')
        form.resetFields()
        close()
      } catch(err) {}
    })
  }
  return (
    <>
      <Modal
        title="标签"
        width={540}
        visible={visible}
        onCancel={close}
        onOk={submit}
      >
        <Form
          form={form}
          labelCol={{ span: 6 }}
        >
          <Form.Item label="问题归类" name="issueTag" rules={[{
            required: true,
            message: '至少选择一项问题分类',
            validator: (_, value) => {
              if (value?.length > 0) {
                return Promise.resolve()
              } else {
                return Promise.reject()
              }
            }
          }]}>
            <Select
              mode="tags"
              optionFilterProp="label"
              options={categories}
              allowClear
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export const useConcludeTicketModal = (ticketId, eventId) => {
  const gotostatuspage = !!eventId
  const [visible, setVisible] = useState(false)
  const [concluded, setConCluded] = useState(false)
  const component = ReactDOM.createPortal(<ConcludeTicket visible={visible} close={() => setVisible(false)} id={ticketId} concluded={concluded}></ConcludeTicket>, document.querySelector('body'))

  return {
    open: (concluded = false) => {
      if (gotostatuspage) {
        if (concluded) {
          return Modal.warning({  
            title: '暂不支持',
            content: 'statuspage稍后支持',
          })
        }
        // 去statuspage系统总结
        let url = `${process.env.REACT_APP_STATUSPAGE_HOST}/detail?eventId=${eventId}`
        if (process.env.NODE_ENV === 'development') {
          window.open(url)
        } else {
          dd.biz.util.openLink({  
            url,
          })
        }
      } else {
        setVisible(true)
        setConCluded(concluded)
      }
    },
    component: component
  }
}

export const useUpdateTicketTagModal = (ticketId) => {
  const [visible, setVisible] = useState(false)
  const component = ReactDOM.createPortal(<UpdateTicketTag visible={visible} close={() => setVisible(false)} id={ticketId}></UpdateTicketTag>, document.querySelector('body'))

  return {
    open: () => setVisible(true),
    component: component
  }
}
