import ReactDOM from 'react-dom/client'
import { useEffect } from 'react';
import { Modal, Select, Button, notification, Form } from 'antd'
import { QueryClient, QueryClientProvider } from 'react-query';
import { ConfigProvider } from "antd";
import zhCN from 'antd/lib/locale/zh_CN';

import { useDealer, useSender } from './useHistory'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false
    },
    mutations: {
      retry: false
    }
  }
});

export default function modifyDealer(ticketId, dealerId, assistants, cb) {
  console.log(assistants)
  let vd = document.createElement('div')
  let vrd = ReactDOM.createRoot(vd)

  function MModal() {
    const { data, modify } =  useDealer()
    const { data: data1 } = useSender()
    const [form] = Form.useForm()

    useEffect(() => {
      form.setFieldsValue({
        dealerId: dealerId,
        assistants: assistants
      })
    }, [form])
  
    return <Modal
      visible={true}
      footer={null}
      title="修改处理人"
      onCancel={() => {
        vrd.render(null)
      }}
      centered
    >
      <Form form={form}>
        <Form.Item label="处理人" name="dealerId">
          <Select className='w-full'>
            {data.map(item => <Select.Option key={item.dealerId} value={item.dealerId}>{item.dealerName}</Select.Option>)}
          </Select>
        </Form.Item>
        <Form.Item label="辅助处理人" name="assistants">
          <Select 
            mode="multiple" 
            className='w-full'
            filterOption={(input, option) =>
              option.children.includes(input)
            }
            optionFilterProp="senderName"
          >
            {data1.map(item => <Select.Option key={item.senderId} value={item.senderId}>{item.senderName}</Select.Option>)}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button className="w-full" type="primary" onClick={async () => {
            const values = form.getFieldsValue()
            try {
              await modify({
                ticketId: Number(ticketId),
                ...values
              })
              notification.success({
                message: '修改成功'
              })
              cb && cb()
              vrd.render(null)
            } catch (err) {
              console.log(err)
            }
          }}>确定</Button>
        </Form.Item>
      </Form>
    </Modal>
  }
    
  vrd.render(
    <ConfigProvider locale={zhCN}>
      <QueryClientProvider client={queryClient}>
        <MModal />
      </QueryClientProvider>
    </ConfigProvider >
  )
}
