import { useParams } from 'react-router-dom'
import { useGroup } from '../logic/useGroup'
import * as dd from 'dingtalk-jsapi'

const EnterGroup = () => {
  const { ticketId } = useParams()
  useGroup(ticketId, () => {
    dd.biz.navigation.close()
  })
}

export default EnterGroup
