import {useMemo, useState} from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {Button, message, Modal, Space, Steps, Tag, Tooltip} from 'dingtalk-design-desktop'
import {useTicket} from '../logic/useTicket'
import {ExclamationCircleOutlined} from '@ant-design/icons'
import {format} from 'date-fns'
import {
  useUpdateTicketModal,
  useConcludeTicketModal,
  useUpdateTicketTagModal
} from '../components/UpdateTicket'
import openModifyDealerModal from '../logic/modifyDealer'
import * as dd from 'dingtalk-jsapi'
import {useSender} from '../logic/useHistory'
import {PageContainer, PageContainerAction, PageContainerActionType} from "dingtalk-design-mobile";
import TextArea from "dingtalk-design-desktop/es/input/TextArea";
import {LinkOutlined} from "dd-icons";
import copy from "copy-to-clipboard";
import {Descriptions} from "antd";
import {HostMap} from "../App";

const {Step} = Steps

const TickDetail = (props) => {
  let {ticketId} = useParams()
  if (!ticketId) ticketId = props.id
  const {data, deleteIt, refetch} = useTicket(ticketId)
  const {component, open} = useUpdateTicketModal(ticketId)
  const {data: senders} = useSender()
  const {component: component2, open: open2} = useConcludeTicketModal(ticketId, data.eventId)
  const {component: component3, open: open3} = useUpdateTicketTagModal(ticketId)
  const location = useLocation();
  const navigate = useNavigate();

  const senderMap = useMemo(() => {
    return senders.reduce((acc, cur) => {
      acc[cur.senderId] = cur.senderName
      return acc
    }, {})
  }, [senders])

  const [visible, setVisible] = useState();
  const [feedback, setFeedback] = useState();

  const gotoStatusPagePublish = () => {
    let url = `https://${HostMap[process.env.REACT_APP_ENV].statuspage}/publish?ticketId=${ticketId}`
    if (process.env.NODE_ENV === 'development') {
      window.open(url)
    } else {
      dd.biz.util.openLink({
        url,
      })
    }
  }

  const openReport = (url) => {
    if (process.env.NODE_ENV === 'development') {
      window.open(url)
    } else {
      dd.biz.util.openLink({
        url,
      })
    }
  }

  const actions: PageContainerAction[] = []

  if (!data.eventId) {
    actions.push(
      {
        key: "转为事故",
        text: "转为事故",
        type: PageContainerActionType.DEFAULT,
        onClick: (close)=> {
          gotoStatusPagePublish()
          close()
        }
      }
    )
  }

  switch (data.ticketStatus) {
    case 1:
      actions.push(
        {
          key: "总结",
          text: "总结",
          type: PageContainerActionType.OK,
          onClick: () =>  open2(false)
        }
      )
      break
    case 2: {
      actions.push(
        {
          key: "结单",
          text: "结单",
          type: PageContainerActionType.OK,
          onClick: () =>  setVisible(true)
        }
      )
      break
    }
    default:
      break
  }

  return (
    <PageContainer
      title={
        (
          <>
            <img alt="工单" className="w-[43px]"
                 src="https://gllueassets.oss-cn-hangzhou.aliyuncs.com/rome/ticket-system/ticket.png"/>
            工单详情
          </>
        )
      }
      hasBack={true}
      onBeforeBack={() => {
        const {state} = location;
        navigate("/history", {state});
      }}
      strongMainAction={true}
      actions={actions}
    >
      <div>
        {component}
        {component2}
        {component3}
        <div className="flex flex-col justify-between flex-grow bg-red px-[20px]">
          <Descriptions
            className=" padding-[0px]"
            layout="horizontal"
            column={1}
          >
            <Descriptions.Item label="工单 Id">
              <Space>
                <span style={{color: "grey"}}>#{data.ticketId}</span>
                <Tooltip placement="bottom" title="复制链接">
                  <LinkOutlined
                    style={{cursor: "pointer"}}
                    onClick={() => {
                      const url = window.location.origin + "/ticket_detail/" + data.ticketId
                      const content = `#${data.ticketId} ${data.issueDesc} \n ${url}`
                      if (copy(content) === true) {
                        message.success("复制链接成功")
                      }
                    }}
                  />
                </Tooltip>
              </Space>
            </Descriptions.Item>
            <Descriptions.Item label="code">
              <a
                href={`http://${data.ip}`}
                target="_blank"
                rel="noreferrer"
              >{data.code}</a>
            </Descriptions.Item>
            <Descriptions.Item label="客户简称">
              <a
                href={`https://${HostMap[process.env.REACT_APP_ENV].client}/crm/client/detail?id=${data.crmId}`}
                target="_blank"
                rel="noreferrer"
              >{data.name}</a>
            </Descriptions.Item>
            <Descriptions.Item label="环境类型">{data.systemType}</Descriptions.Item>
            <Descriptions.Item label="分支类型">{data.branchType}</Descriptions.Item>
            {data.stage > 0 && <Descriptions.Item label="stage">{data.stage}</Descriptions.Item>}
            <Descriptions.Item label="客户类型">{data.clientType}</Descriptions.Item>
            <Descriptions.Item label="处理人">
              {data.dealerName}
              {data.ticketStatus !== 3 &&
                <Button
                  type="link"
                  className="!p-0 !h-[22px]"
                  onClick={() => openModifyDealerModal(ticketId, data.dealerId, data.assistants, () => {
                    refetch()
                  })}
                >
                  修改
                </Button>
              }
            </Descriptions.Item>
            {data.assistants?.length > 0 && <Descriptions.Item label="协助人">
              {data.assistants.map(item => senderMap[item]).join(',')}
            </Descriptions.Item>}
            {data.grafanaLink && <Descriptions.Item label="grafana">
              <a
                href={data.grafanaLink}
                target="_blank"
                rel="noreferrer"
              >点我跳转</a>
            </Descriptions.Item>}
            <Descriptions.Item label="历史工单">
              <a
                href={`${window.location.origin}/history?code=${data.code}`}
                target="_blank"
                rel="noreferrer"
              >
                点我跳转
              </a>
            </Descriptions.Item>
            {data.detailLink && <Descriptions.Item label="事故报告">
              <Button
                type="link"
                className="!p-0 !h-[22px]"
                onClick={() => openReport(data.detailLink)}
              >
                点我跳转
              </Button>
            </Descriptions.Item>}
            <Descriptions.Item label="工单创建时间">
              {format(new Date((data.createTime || 0) * 1000), 'yyyy-MM-dd HH:mm:ss')}
            </Descriptions.Item>
            <Descriptions.Item label="工单确认时间">
              {format(new Date((data.confirmTime || 0) * 1000), 'yyyy-MM-dd HH:mm:ss')}
            </Descriptions.Item>
          </Descriptions>
          <Steps direction="vertical" current={data.ticketStatus} size="small">
            <Step
              title={(
                <div className="flex items-center justify-between">
                  <div>问题描述</div>
                  <div className="flex">
                    {data.ticketStatus === 1 &&
                      <Button
                        className="ml-[12px]"
                        size="small"
                        type="primary"
                        onClick={open}
                      >
                        更新
                      </Button>
                    }
                    {data.ticketStatus !== 3 &&
                      <Button
                        className="ml-[12px]"
                        size="small"
                        type="primary"
                        onClick={open3}
                      >
                        {data.tagInfos?.length > 0 ? '更新' : '打'}标签
                      </Button>
                    }
                  </div>
                </div>
              )}
              description={(
                <div className="w-full mt-[10px] flex justify-between">
                  <Space direction="vertical">
                    <div>{data.issueDesc}</div>
                    <div className="mt-[4px] flex">
                      <div>
                        {data.tagInfos?.map(each =>
                          <Tag key={each.tagDesc} size="small">{each.tagDesc}</Tag>
                        )}
                      </div>
                    </div>
                  </Space>
                  <div className="flex-shrink-0 ml-[12px] w-[100px] flex flex-col items-end">
                    <div>{format(new Date((data.updateTicketTime || 0) * 1000), 'yyyy-MM-dd')}</div>
                    <div>{format(new Date((data.updateTicketTime || 0) * 1000), 'HH:mm:ss')}</div>
                  </div>
                </div>
              )}
            />
            <Step
              title={(
                <div className="flex items-center justify-between">
                  <div>总结</div>
                  <div className="flex">
                    {data.ticketStatus === 2 &&
                      <Button
                        className="ml-[12px]"
                        size="small"
                        type="primary"
                        onClick={() => open2(true)}
                      >
                        修改
                      </Button>
                    }
                  </div>
                </div>
              )}
              description={data.ticketStatus > 1 && (
                <div className="flex justify-between mt-[10px]">
                  <div className="w-[280px]">
                    <div dangerouslySetInnerHTML={{__html: data.concludeDesc}}/>
                  </div>
                  <div className="flex-shrink-0 ml-[12px] w-[100px] flex flex-col items-end">
                    <div>{format(new Date((data.concludeTime || 0) * 1000), 'yyyy-MM-dd')}</div>
                    <div>{format(new Date((data.concludeTime || 0) * 1000), 'HH:mm:ss')}</div>
                  </div>
                </div>
              )}
            />
            <Step
              title="结单"
              description={data.ticketStatus === 3 && (
                <div className="flex justify-between mt-[10px]">
                  <div className="relative">
                    {data.feedback ? data.feedback : "已结单：无反馈"}
                    <img
                      className="absolute h-[60px] left-[30px] top-[-60%]"
                      alt="conclude"
                      src="https://gllueassets.oss-cn-hangzhou.aliyuncs.com/rome/ticket-system/conclude.png"
                    />
                  </div>
                  <div className="flex-shrink-0 ml-[12px] w-[100px] flex flex-col items-end">
                    <div>{format(new Date((data.closeTime || 0) * 1000), 'yyyy-MM-dd')}</div>
                    <div>{format(new Date((data.closeTime || 0) * 1000), 'HH:mm:ss')}</div>
                  </div>
                </div>
              )}
            />
          </Steps>
        </div>
        <Modal
          title='结单'
          icon={(<ExclamationCircleOutlined/>)}
          visible={visible}
          onOk={async () => {
            await deleteIt(feedback)
            setVisible(false)
          }}
          onCancel={() => setVisible(false)}
        >
          <p style={{color: "black"}}>告诉我们您对该工单的建议</p>
          <TextArea placeholder={"请填写"} rows={4}
                    onChange={(data) => setFeedback(data.target.value)}/>
        </Modal>
      </div>
    </PageContainer>
  )
}

export default TickDetail
