import { useQuery, useMutation } from 'react-query'
import { queryHistory, queryDealer, querySender, modifyDealer } from '../api'

export const useTicketHistory = (params) => {
  if (params.ticketStatus === void 0) {
    params.ticketStatus = -1
  }
  const { data, refetch } = useQuery(['ticket-history', params], queryHistory, {
    placeholderData: {
      ticketInfo: [],
      total: 0,
    }
  })

  return {
    data,
    refetch
  }
}

export const useDealer = () => {
  const { data } = useQuery('dealer', queryDealer, {
    placeholderData: {
      dealerInfo: []
    }
  })

  const mutation = useMutation('modity-dealer', (data) => modifyDealer(data), {})

  return {
    data: data.dealerInfo,
    modify: mutation.mutateAsync,
  }
}

export const useSender = () => {
  const { data } = useQuery('sender', querySender, {
    placeholderData: {
      senderInfo: []
    }
  })

  return {
    data: data.senderInfo
  }
}
