import { useCallback, useEffect } from "react"
import { enterGroup } from '../api'

export const useGroup = (ticketId, callback) => {
  const fn = useCallback(async() => {
    try {
      const data = await enterGroup(ticketId)
      window.location.href = data.redirectUrl
      if (typeof callback === 'function') {
        callback()
      }
    } catch(_) {
      setTimeout(() => {
        callback()
      }, 1500)
    }
  }, [ticketId, callback])

  useEffect(() => {
   fn()
  }, [fn])
}
