import { Select, Form, Input, Switch } from 'antd'
import { useEffect, useState } from 'react'
import { useSearchParams, createSearchParams } from 'react-router-dom'
import { useDealer, useSender } from '../logic/useHistory'

const Option = Select.Option
const FormItem = Form.Item

const useHistorySearchParams = () => {
  const { data: dealer } = useDealer()
  const { data: sender } = useSender()
  const [form] = Form.useForm()
  const [searchParams, setSearchParams] = useSearchParams()
  const [formValue, setFormValue] = useState({
    ticketStatus: Number(searchParams.get('ticketStatus') || -1),
    dealerId: searchParams.get('dealerId') || undefined,
    isSender: searchParams.get('isSender') === 'true',
    code: searchParams.get('code') || undefined,
    senderId: searchParams.get('senderId') || undefined,
  })

  useEffect(() => {
    form.setFieldsValue(formValue)
  }, [form, formValue])

  const onValuesChange = () => {
    setFormValue(form.getFieldsValue())
  }

  useEffect(() => {
    let values = Object.assign({}, formValue)
    for (let key in values) {
      if (values[key] === undefined) {
        delete values[key]
      }
    }
    let kvpair = createSearchParams(values)
    setSearchParams(kvpair)
  }, [formValue, setSearchParams])

  const component = (
    <Form
      size="small"
      layout="inline"
      form={form}
      onValuesChange={onValuesChange}
    >
      <FormItem label="clientCode" name="code">
        <Input placeholder="请输入clientCode" />
      </FormItem>
      <FormItem label="状态" name="ticketStatus">
        <Select 
          style={{
            width: 160
          }}
          placeholder="选择状态"
          filterOption={false}
          allowClear={true}
        >
          <Option value={-1}>全部</Option>
          <Option value={0}>分配中</Option>
          <Option value={1}>处理中</Option>
          <Option value={2}>已总结</Option>
          <Option value={3}>已关闭</Option>
        </Select>
      </FormItem>
      <FormItem label="处理人" name="dealerId" >
        <Select
          style={{
            width: 160
          }}
          placeholder="选择处理人"
          filterOption={false}
          allowClear
        > 
          {dealer?.map((each) => {
            return <Option key={each.dealerId} value={each.dealerId}>{each.dealerName}</Option>
          })}
        </Select>
      </FormItem>
      <FormItem label="发起人" name="senderId" >
        <Select
          style={{
            width: 160
          }}
          showSearch
          filterOption={(input, option) =>
            option.children.includes(input)
          }
          optionFilterProp="senderName"
          placeholder="选择发起人"
          allowClear
        > 
          {sender?.map((each) => {
            return <Option key={each.senderId} value={each.senderId}>{each.senderName}</Option>
          })}
        </Select>
      </FormItem>
      <FormItem label="只看自己" name="isSender" valuePropName="checked">
        <Switch />
      </FormItem>
    </Form>
  )
  
  return {
    component,
    formValue,
    setFormValue
  }
}

export default useHistorySearchParams
