import ajax from './ajax'

export const queryTicketDetail = async ({queryKey}) => {
  const res = await ajax.get(`/ticket/${queryKey[1]}`)

  return res
}

export const createTicket = async (data) => {
  const res = await ajax.post(`/ticket`, {
    ...data,
  })

  return res
}

export const updateTicket = async (ticketId, data) => {
  const res = await ajax.put(`/ticket`, {
    ...data,
    ticketId
  })

  return res
}

export const deleteTicket = async (ticketId, feedback) => {
  const res = await ajax.delete(`/ticket/${ticketId}`, {
    data: {
      feedback,
    }
  })

  return res
}

export const concludeTicket = async (ticketId, data) => {
  const res = await ajax.post(`/ticket_conclude`, {
    ...data,
    ticketId,
  })

  return res
}

export const updateConclude = async (ticketId, data) => {
  const res = await ajax.put(`/ticket_summary`, {
    ...data,
    ticketId,
  })

  return res
}

export const updateTicketTag = async (ticketId, data) => {
  const res = await ajax.put(`/ticket/tags`, {
    ...data,
    ticketId,
  })

  return res
}

export const queryHistory = async ({ queryKey }) => {
  const res = await ajax.get('/ticket/history', {
    params: queryKey[1]
  })

  return res
}

export const modifyDealer = async (data) => {
  const res = await ajax.put('/dealer', data)

  return res
}
