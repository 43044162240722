import { useMutation, useQuery } from "react-query"
import {
  queryTicketDetail,
  updateTicket,
  deleteTicket,
  concludeTicket,
  updateTicketTag,
  updateConclude,
} from "../api"

export const useTicket = (ticketId) => {
  const { data, refetch } = useQuery(['ticket-detail', ticketId], queryTicketDetail)
  const mutation = useMutation('update-ticket', (data) => updateTicket(Number(ticketId), data), {
    onSuccess: () => {
      refetch()
    }
  })
  const mutation2 = useMutation('delete-ticket', (feedback) => deleteTicket(ticketId, feedback), {
    onSuccess: () => {
      refetch()
    }
  })
  const mutation3 = useMutation('conclude-ticket', (data) => concludeTicket(Number(ticketId), data), {
    onSuccess: () => {
      refetch()
    }
  })
  const mutation4 = useMutation('update-ticket-tag', (data) => updateTicketTag(Number(ticketId), data), {
    onSuccess: () => {
      refetch()
    }
  })
  const mutation5 = useMutation('conclude-ticket', (data) => updateConclude(Number(ticketId), data), {
    onSuccess: () => {
      refetch()
    }
  })

  return { 
    data: data || {},
    refetch,
    update: mutation.mutateAsync,
    deleteIt: mutation2.mutateAsync,
    conclude: mutation3.mutateAsync,
    updateTag: mutation4.mutateAsync,
    updateConclude: mutation5.mutateAsync,
  }
}
