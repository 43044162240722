import { useEffect, useMemo, useState } from 'react'
import { format } from 'date-fns'
import { useTicketHistory } from "../logic/useHistory"
import { enterGroup } from '../api'
import useHistorySearchParams from '../components/DealerSelect'
import {useLocation, useNavigate} from "react-router-dom";
import {
  List,
  Button,
  Descriptions,
  PageHeader,
  Spin
} from "antd";
import {LinkOutlined} from 'dd-icons';
import copy from "copy-to-clipboard";
import {Tooltip, message, Space} from "dingtalk-design-desktop";


const History = () => {
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [currentPage, setCurrentPage]= useState(1);
  const [loading, setLoading] = useState(true)
  const { component, formValue, setFormValue } = useHistorySearchParams()
  const params = useMemo(() => {
    return Object.assign({}, formValue, {
      page,
      pageSize
    })
  }, [formValue, page, pageSize])

  const { data } = useTicketHistory(params)
  const { ticketInfo = [], total } = data

  const location = useLocation();
  const navigate = useNavigate();

  const handleChange = (page, pageSize) => {
    setPage(page)
    setPageSize(pageSize)
    setCurrentPage(page)
  }

  useEffect(() => {
    const { state } = location;
    const fv = state && state.formValue ? state.formValue : formValue
    const cp = state && state.currentPage ? state.currentPage : currentPage
    setFormValue(fv)
    setPage(cp)
    setCurrentPage(cp)
    setLoading(false)
  }, [currentPage, formValue, setFormValue, location])


  const getTicketStatus = (ticketStatus) => {
    switch (ticketStatus) {
      case 0:
        return '分配中'
      case 1:
        return '处理中'
      case 2:
        return '已总结'
      case 3:
        return '已关闭'
      default:
        return ''
    }
  }


  return (
    <>
      <PageHeader
        title="工单服务台"
        extra={[
          <Button type="primary" onClick={() => {navigate("/open_ticket")}}>
            创建工单
          </Button>,
        ]}
        className="site-page-header"
      />
      <Spin key="spin" spinning={loading}>
        <List
          bordered
          header={component}
          dataSource={ticketInfo}
          renderItem={item => (
            <List.Item
              key={item.ticketId}
              actions={[
                <Button type="primary" size={"small"} onClick={async () => {
                  const data = await enterGroup(item.ticketId)
                  window.location.href = data.redirectUrl
                }}>进群</Button>,
                <Button type="primary" size={"small"} onClick={() => {
                  navigate(`/ticket_detail/${item.ticketId}`, {state: {formValue, currentPage}})
                }}>查看详情</Button>
              ]}
            >
              <List.Item.Meta
                title={(
                  <Space>
                    <a href={item.href} style={{color: "#4285F4"}}>
                      <span style={{color: "grey"}}>#{item.ticketId}</span> {item.issueDesc}
                    </a>
                    <Tooltip placement="bottom" title="复制链接">
                      <LinkOutlined
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          const url = window.location.origin + "/ticket_detail/" + item.ticketId
                          const content = `#${item.ticketId} ${item.issueDesc} \n ${url}`
                          if (copy(content) === true) {
                            message.success("复制链接成功")
                          }
                        }}
                      />
                    </Tooltip>
                  </Space>
                )}
                description={(
                  <Descriptions size="small">
                    <Descriptions.Item label="客户 Code">{item.code}</Descriptions.Item>
                    <Descriptions.Item label="提交人">{item.senderNick}</Descriptions.Item>
                    <Descriptions.Item label="状态">{getTicketStatus(item.ticketStatus)}</Descriptions.Item>
                    <Descriptions.Item label="创建时间">{format(new Date(item.createTime * 1000), 'yyyy-MM-dd HH:mm:ss')}</Descriptions.Item>
                  </Descriptions>
                )}
              />
            </List.Item>
          )}
          itemLayout="vertical"
          size="large"
          pagination={{
            pageSize: pageSize,
            current: currentPage,
            total: total,
            onChange: handleChange
          }}
        />
      </Spin>
    </>
  )
}

export default History
